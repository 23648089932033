@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

footer {
    padding: 4rem 0;
    background-color: #007fa4;
    color: $footer-color;
    font-size: 1rem;
    text-align: center;
    background: linear-gradient(90deg, $footer-bg-deep-blue, $footer-bg-dark-blue);
    p {
        font-family: 'BentonSans-Book';
        letter-spacing: .015em;
        margin: 0;
        &.font-weight-bold {
            font-family: 'BentonSans-Medium';
            letter-spacing: .04em;
        }
    }
    a {
        font-family: 'BentonSans-Regular';
        color: $footer-color;
        opacity: 1;
        &:hover {
            color: $footer-color;
        }
    }
    #footer-navigation {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid rgba(255,255,255,1);
        .col {
            &.footer-nav {
                display: none;
            }
            &:last-child {
                p {
                    margin-bottom: 1.8rem;
                }
                a {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    span {
                        display: inline-block;
                        flex-grow: 0;
                        flex-shrink: 0;
                        font-family: 'BentonSans-Medium';
                        opacity: 0.6;
                    }
                    .icon {
                        flex-grow: 0;
                        flex-shrink: 0;
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        margin-right: .5rem;
                        &:before {
                            display: none;
                        }
                        &.icon-envelope {
                            background-image: url(/images/icon-envelope-white.svg);
                        }
                        &.icon-user {
                            background-image: url(/images/icon-user-white.svg);
                        }
                    }
                }
            }
            a {
                display: inline-block;
                line-height: 1.333em;
            }
        }
        h3 {
            font-family: 'BentonSans-Medium';
            letter-spacing: .04em;
            margin-bottom: 1.5rem;
            font-size: 1em;
            a {
                color: inherit;
                opacity: 1;
                font-family: 'BentonSans-Bold';
            }
        }
        a {
            font-family: 'BentonSans-Medium';
            line-height: 1.2em;
            letter-spacing: .03em;
        }
        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }
    }
    #footer-copy {
        padding-top: 3rem;
        margin-bottom: 2rem;
        font-size: .91em;
        .col-12 {
            img {
                max-width: 300px;
                width: 100%;
                margin: 0 auto 2.25rem;
                display: block;
            }
            p {
                margin-bottom: 1.5rem;
            }
        }
        .col {
            & > a {
                display: inline-block;
            }
            & > p {
                &:nth-child(2) {
                    padding: 1.5rem 0;
                }
                a {
                    display: block;
                    margin-bottom: 1rem;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
                span {
                    display: none;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    footer {
        font-size: .875rem;
        text-align: left;
        p {
            opacity: .6;
            a {
                opacity: 1;
            }
            &.font-weight-bold {
                opacity: 1;
            }
        }
        #footer-navigation {
            padding-bottom: 2.125rem;
            border-bottom: 1px solid rgba(255,255,255,.5);
            .col {
                &.footer-nav {
                    display: block;
                    margin-right: 2.5rem;
                    ul {
                        padding-right: 1.25rem;
                        li {
                            a {
                                opacity: .6;
                            }
                            margin-bottom: 1.25rem;
                        }
                    }
                    &:nth-child(4) {
                        border-right: 1px solid rgba(255,255,255,.5);
                    }
                }
                &:last-child {
                    p {
                        margin-bottom: 1.5rem;
                    }
                    a {
                        margin-bottom: 1.5rem;
                        opacity: 1;
                        justify-content: flex-start;
                        align-items: flex-start;
                        span {
                            flex-grow: 1;
                            flex-shrink: 1;
                        }
                    }
                }
            }
            h3 {
                margin-bottom: 1.5rem;
                font-family: 'BentonSans-Bold';
            }
        }
        #footer-copy {
            padding-top: 2.625rem;
            .col-12 {
                img {
                    margin: 0 5rem 3.575rem 0;
                }
                p {
                    margin-bottom: 0;
                }
            }
            .col {
                & > p {
                    a {
                        display: inline;
                    }
                    span {
                        display: inline;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    footer {
        #footer-copy {
            .col-12 {
                img {
                    margin: 0 6.5rem 2.5rem 0;
                }
            }
        }
    }
}
