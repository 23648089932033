@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-slogan {
    position: relative;
    height: 80vw;
    min-height: 410px;
    overflow: hidden;
    .container {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        .slogan-content {
            width: 100%;
            opacity: 0;
            transform: translateY(10px);
            transition: opacity .3s ease-in, transform .3s ease-in;
            h2 {
                margin-bottom: .75em;
            }
            p {
                color: $main-text-color;
            }
        }
    }
    .branch {
        content: '';
        display: block;
        z-index: 0;
        width: 60%;
        height: 95%;
        position: absolute;
        right: 0;
        top: 10%;
        transform: translateZ(2px);
        background-image: url(/images/bg-slogan-mobile.webp);
        background-repeat: no-repeat;
        background-position: center right;
        background-size: contain;
    }
    &.visible {
        .container {
            .slogan-content {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-slogan {
        height: 500px;
        .container {
            .slogan-content {
                width: 70%;
            }
        }
        .branch {
            background-image: url(/images/bg-slogan-desktop.webp);
        }
    }
}
@include media-breakpoint-up(md) {
    .component-slogan {
        height: 410px;
    }
}
@include media-breakpoint-up(lg) {
    .component-slogan {
        height: 550px;
        .container {
            .slogan-content {
                width: 55%;
            }
        }

    }
}
@include media-breakpoint-up(xl) {
    .component-slogan {
        height: 650px;
    }
}
