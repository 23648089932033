@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-chart-allofresh {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .step-item {
        width: 200px;
        margin-bottom: 10px;
        position: relative;
        text-align: center;
        .step-item-image {
            position: relative;
            max-width: 200px;
            display: inline-block;
            img {
                width: 200px;
                border: 3px solid transparent;
                border-radius: 50%;
                transition : border 200ms ease-out;
                cursor: pointer;
            }
            a {
                position: absolute;
                top: 0;
                right: 0;
            }
            &:hover {
                img {
                    border-color: $main-second-text-color;
                }
            }
        }
        .step-item-hint {
            width: 200px;
            position: relative;
            padding-bottom: 35px;
            padding-top: 5px;
            display: inline-block;
            z-index: 0;
            p {
                text-align: center;
                margin-bottom: 5px;
                font-family: 'CeraPro-Medium';
                color: $main-second-text-color;
                line-height: 1.2em;
                background-color: #fff;
                position: relative;
                z-index: 1;
            }
            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 2px;
                height: 30px;
                bottom: 5px;
                left: 50%;
                transform: translateX(-50%);
                background-color: $main-text-color;
                z-index: 0;
            }
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 7px solid $main-text-color;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 0;
            }
        }
        .step-item-description {
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            background-color: $main-third-text-color;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 200px;
            min-height: 200px;
            color: #ffffff;
            padding: 40px 10px 10px;
            z-index: 2;
            text-align: center;
            .btn-close {
                position: absolute;
                top: 0;
                right: 10px;
                font-size: 1.5rem;
                cursor: pointer;
            }
            & > *:not(.btn-close) {
                width: 100%;
            }
            &.visible {
                display: flex;
            }
        }
        &:last-child {
            margin-bottom: 0;
            .step-item-hint {
                padding-bottom: 0;
                &:after,
                &:before {
                    display: none;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-chart-allofresh {
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        .step-item {
            flex-grow: 1;
            flex-basis: 33%;
            margin-bottom: 0;
            .step-item-hint {
                padding-bottom: 0;
                padding-top: 15px;
                p {
                    padding: 5px 0;
                }
                &:before,
                &:after {
                    display: none;
                }
            }
            &:nth-child(1),
            &:nth-child(2) {
                text-align: left;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: auto;
                    height: 4px;
                    top: 98px;
                    left: 195px;
                    right: 25px;
                    background-color: $main-text-color;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-top: 7px solid transparent;
                    border-bottom: 7px solid transparent;
                    border-left: 10px solid $main-text-color;
                    top: 93px;
                    right: 15px;
                }
            }
            &:nth-child(4),
            &:nth-child(5) {
                text-align: right;
                .step-item-description {
                    left: auto;
                    right: 0;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: auto;
                    height: 4px;
                    top: 98px;
                    right: 195px;
                    left: 25px;
                    background-color: $main-text-color;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-top: 7px solid transparent;
                    border-bottom: 7px solid transparent;
                    border-right: 10px solid $main-text-color;
                    top: 93px;
                    left: 15px;
                }
            }
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 2;
            }
            &:nth-child(3) {
                order: 3;
                flex-grow: 0;
                max-width: 200px;
                .step-item-hint {
                    padding-bottom: 50px;
                    &:before {
                        display: block;
                        bottom: 25px;
                        width: 4px;
                        height: auto;
                        top: -5px;
                    }
                    &:after {
                        display: block;
                        bottom: 15px;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-top: 10px solid $main-text-color;
                    }
                }
            }
            &:nth-child(4) {
                order: 6;
            }
            &:nth-child(5) {
                order: 5;
            }
            &:nth-child(6) {
                order: 4;
                flex-grow: 0;
                max-width: 200px;
            }
        }
    }
}
