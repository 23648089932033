@import '../../css/variables';
.component-select {
    position: relative;
    border: 1px solid $main-text-color;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 1rem;
    padding-left: 0.75rem;
    height: 50px;
    background-color: white;
    max-width: 100%;
    font-family: 'BentonSans-Regular';

    &>span {
        flex-grow: 1;
        flex-shrink: 1;
        line-height: 3em;
        padding-right: 5px;
        max-width: calc(100% - 10px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $main-text-color;
        font-size: 1rem;
    }

    &>button {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 10px;
        margin: 0;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: none;
        background: white;
        outline: none;
        position: relative;

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6px 11px 6px;
            border-color: transparent transparent $main-text-color transparent;
            margin-bottom: 1px;
            opacity: 0.75;

            @media all and (-ms-high-contrast:none) {
                position: absolute;
                top: 7px;
                right: 50%;
                transform: translateX(50%);
            }
        }

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 11px 6px 0 6px;
            border-color: $main-text-color transparent transparent transparent;
            margin-top: 1px;
            opacity: 0.75;

            @media all and (-ms-high-contrast:none) {
                position: absolute;
                right: 50%;
                transform: translateX(50%);
            }
        }
    }

    .select-variants {
        flex: 2;
        min-width: calc(100% + 2px);
        width: 100%;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: calc(100% + 2px);
        left: -1px;
        right: 0;
        transition: max-height .2s ease-in;
        z-index: 3;
        &>ul {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            list-style: none;
            width: 100%;
            height: $select-variants-height;
            overflow-y: scroll;
            background-color: white;
            border: 1px solid $main-text-color;

            li {
                min-height: 30px;
                padding: 15px;
                margin-bottom: 0;
                line-height: 1;
                font-size: 1rem;
                background-color: transparent;
                transition: background-color .2s, color .2s;
                white-space: pre-wrap;
                box-sizing: border-box;
                cursor: pointer;
                &.selected {
                    background-color: rgba(100,102,107, .25);
                }
                &:hover {
                    background-color: $main-second-text-color;
                    color: white;
                }
                &::before {
                    content: '';
                }
            }
            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, .5);
            }
        }
        &.active {
            max-height: $select-variants-height;
        }
    }
}
