@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import 'variables';

@import 'fonts';
@keyframes read-more-animation {
    0% {
      transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
    }
}

html {
    font-size: 16px;
}

.no-topper main {
    padding-top: $navigation-height-mobile;
}

body {
    color: $main-text-color;
    font-family: 'CeraPro-Regular';

    &.disable-scrolling {
        overflow: hidden;
    }
}

main {
    padding-top: calc(#{$topper-height} + #{$navigation-height-mobile});
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'CeraPro-Medium';
        color: $main-second-text-color;

        sup {
            font-family: 'CeraPro-Regular';
        }
    }

    h2 {
        font-size: 1.4375rem;
        margin-bottom: 1.2rem;
    }

    h3 {
        font-size: 1.25rem;
    }
    h4, h5, h6 {
        font-size: 1rem;
    }

    p {
        font-size: 1rem;
        a {
            color: inherit;
            text-decoration: underline;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    a {
        &.download, &.read-more {
            display: block;
            font-family: 'CeraPro-Bold';
            color: $main-second-text-color;
            text-decoration: none;
            font-size: 1rem;

            &:hover {
                color: $main-second-text-color;

                span {
                    text-decoration: underline;
                }
            }
        }
        &.read-more {
            display: inline-block;
            &:after {
                content: '\003E';
                padding-left: .35rem;
                display: inline-block;
                transform: translateX(0);
                transition: transform 1.3s ease-out;
            }
            &:hover {
                &:after {
                    animation-timing-function: ease-out;
                    animation-duration: 1.3s;
                    animation-iteration-count: infinite;
                    animation-name: read-more-animation;
                }
            }
        }
    }

    b,
    strong {
        font-family: 'CeraPro-Medium';
        font-weight: normal;
    }

    ul {
        padding-top: 0.75em;
        padding-left: 2em;
        margin-bottom: 1.25rem;
        max-width: 100%;
        font-size: 1rem;

        li {
            margin-bottom: 0.375em;
        }

        &.columns-1 {
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;
        }

        &.columns-2 {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
    }
    ol {
        font-size: 1rem;
    }
    hr {
        width: calc(100% - 2rem);
        margin: 0 auto;
        padding: 0;
        border-color: $main-second-text-color;
    }
    small {
        font-size: .75rem;
        &.footnote {
            display: block;
            font-family: 'CeraPro-Regular';
            line-height: 1.45em;
            &.with-sup {
                padding-left: .5em;
                margin-left: 0;
                position: relative;
                sup:first-child {
                    position: absolute;
                    left: 0;
                    top: .5em;
                }
            }
        }
    }

    .component-alt-bg {
        background-color: $component-alt-bg-color;
    }
    .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &.icon-download {
            background-image: url(/images/icon-download.svg);
        }

        &.icon-expand {
            background-image: url(/images/icon-plus.webp);
        }
    }
}

.modal-body {
    h2 {
        font-size: 1.625rem;
    }
}

form {

    label,
    legend {
        display: inline-block;
        font-family: 'BentonSans-Medium';
        font-size: 1.1em;
        padding-bottom: 0;
        margin-bottom: .35rem;

        span {
            font-family: 'BentonSans-Book';
            font-size: 0.85em;
        }
        b, strong {
            font-family: 'BentonSans-Bold';
            font-weight: normal;
        }
    }

    legend {
        padding-left: 1.875em;
        font-size: 1em;
    }

    .form-check {
        label {
            font-family: 'BentonSans-Book';
        }

        input {
            margin-top: .05rem;
        }
    }

    .custom-checkbox,
    .custom-radio {
        padding-left: 0;

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        input[type="radio"]:checked+label:after {
            background-color: $main-text-color;
        }

        input[type="checkbox"]:checked+label:after {
            content: '\2713';
            top: 6px;
            color: $main-text-color;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size: 0.75rem;
            width: initial !important;
        }

        label {
            display: inline-block;
            position: relative;
            padding-left: 1.75em;
            font-size: 1.1rem;
            line-height: 1.75em;
            font-family: 'BentonSans-Book';
            padding-top: 5px;
            padding-bottom: 0;

            &:before {
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                width: 18px;
                height: 18px;
                pointer-events: none;
                content: "";
                background-color: white;
                border: 2px solid $main-text-color;
                transform: translateY(-50%);
            }

            &:after {
                position: absolute;
                left: 4px;
                display: block;
                width: 10px;
                height: 10px;
                content: "";
                background-color: transparent;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .custom-radio {
        label {
            &:before {
                border-radius: 50%;
            }

            &:after {
                border-radius: 50%;
            }
        }
    }

    .custom-checkbox {
        label {
            &:before {
                top: 6px;
                transform: none;
            }

            &:after {
                transform: none;
            }
        }
    }
    .form-error {
        color: $main-second-text-color;
        display: none;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        .icon {
            font-size: 22px;
            width: 22px;
            height: 22px;
            margin-right: 10px;
            flex-grow: 0;
        }
        span {
            font-family: 'BentonSans-Medium';
            flex-grow: 1;
            flex-shrink: 1;
            line-height: 1.2;
        }
    }
    fieldset {
        margin-bottom: 1rem;
        &.form-group {
            display: block;
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    select {
        font-size: 1rem;
        padding: 0.75rem;
        height: 50px;
        border: 1px solid $main-text-color;
        border-radius: 0;
    }

    textarea {
        font-size: 1rem;
        padding: 0.75rem;
        max-height: 200px;
        min-height: 100px;
        width: 100%;
        border: 1px solid $main-text-color;
    }

    .form-select {
        visibility: hidden;
        position: absolute;
    }

    button[type="submit"] {
        border: 2px solid transparent;

        &:hover {
            color: $main-link-color;
            background-color: white;
            border: 2px solid $main-second-text-color;
        }
    }

    .form-group {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        & > label {
            width: 100%;
        }
        & > input,
        & > .component-select {
            width: calc(100% - 37px);
        }
        & > .error-icon {
            color: $main-second-text-color !important;
            display: none;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 22px;
            width: 22px;
            height: 22px;
            margin: 0 5px 0 10px;
        }
        & > .error-holder {
            font-family: 'BentonSans-Medium';
            color: $main-second-text-color !important;
            display: none;
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
        }
        &.error {
            & > label {
                color: $main-second-text-color !important;
            }

            & > input,
            & > .component-select {
                border: 1px solid $main-second-text-color !important;
            }
            & > .error-icon {
                display: block;
            }
            & > .error-holder {
                display: block;
            }
        }

        small {
            font-size: .8em;
        }
    }

    .form-submit {
        span {
            font-family: 'BentonSans-Regular';
            display: none;

            &.error {
                color: $main-second-text-color;
            }
        }
        button.btn[disabled] {
            background: #E6E6E6 !important;
            color: #B8B8B8 !important;
            border-color: transparent !important;
            cursor: not-allowed;
        }

        &.disabled {
            .processing {
                display: block;
            }
        }

        &.error {
            .error {
                display: block;
            }
        }
    }

    &>div:not(.form-group) {
        .form-check {
            label {
                .font-weight-bold {
                    font-family: 'BentonSans-Medium';
                }

                font-family: 'BentonSans-Regular';
                line-height: 1.25em;
                font-size: 1rem;
            }
        }

        p {
            font-family: 'BentonSans-Regular';
            line-height: 1.25em;
            a {
                &:hover {
                    text-decoration: none;
                }

                font-family: 'BentonSans-Medium';
                text-decoration: underline;
            }
            b, strong {
                font-family: 'BentonSans-Medium';
            }
        }

        &.visible {
            display: block;
        }

        display: none;
    }
    &.has-errors {
        .form-error {
            display: flex;
        }
    }
}

.mw-video {
    max-width: 648px !important;
}

.text-bold-red {
    color: $main-second-text-color;
    font-family: 'CeraPro-Bold';
}

table {
    &.table {
        thead {
            background-color: #fff;
            color: $main-second-text-color;
            font-family: 'CeraPro-Medium';
            th {
                border-top: none;
                border-bottom: none;
                white-space: nowrap;
                font-weight: 500;
                font-size: 1.125rem;
            }
        }
        tbody {
            tr {
                td {
                    // border-top: 1px solid $main-text-color;
                    font-size: 1.125rem;
                    color: $main-text-color;
                }
            }
        }
    }
}

@supports (-webkit-appearance: none) {
    form {
        input {

            &[type="text"],
            &[type="email"],
            &[type="number"],
            &[type="number"]::-webkit-outer-spin-button,
            &[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }
}

@supports (-moz-appearance: textfield) {
    form {
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
}

@import 'utils';

@media (min-width: 414px) {
    main {
        h2 {
            font-size: 1.875rem;
        }
        h3 {
            font-size: 1.375rem;
        }
        h4, h5, h6 {
            font-size: 1.125rem;
        }
        p {
            font-size: 1.125rem;
        }
        ol {
            font-size: 1.125rem;
        }
        ul {
            font-size: 1.125rem;
        }
        a {
            &.read-more, &.download {
                font-size: 1.125rem;
            }
        }
    }
    .modal-body {
        h2 {
            font-size: 2.375rem;
        }
    }
}
@include media-breakpoint-up(sm) {
    main {
        hr {
            max-width: 480px;
        }
    }
}

@include media-breakpoint-up(md) {
    main {
        .container {
            padding-left: 70px;
            padding-right: 70px;
        }
        hr {
            max-width: 660px;
        }
        ul {
            &.columns-md-1 {
                -webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
            }

            &.columns-md-2 {
                -webkit-column-count: 2;
                -moz-column-count: 2;
                column-count: 2;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .no-topper main {
        padding-top: $navigation-height-desktop;
    }
    main {
        padding-top: calc(#{$topper-height} + #{$navigation-height-desktop});
        .container {
            padding-left: 90px;
            padding-right: 90px;
        }
        hr {
            max-width: 900px;
        }
        ul {
            &.columns-lg-1 {
                -webkit-column-count: 1;
                -moz-column-count: 1;
                column-count: 1;
            }

            &.columns-lg-2 {
                -webkit-column-count: 2;
                -moz-column-count: 2;
                column-count: 2;
            }
        }
        small {
            &.with-sup {
                margin-left: -.5em;
            }
        }
        .w-lg-25 {
            width: 25% !important;
        }
    }
    form {
        .form-group {
            & > .error-holder {
                width: auto;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    main {
        hr {
            max-width: 1080px;
        }
    }
}

sup.required {
  color: red;
}

label:has(+ input[data-validators~="phone"]),
label:has(+ input[data-validators~="email"]),
label:has(+ input[data-validators~="required"]),
label:has(+ textarea[data-validators~="required"]),
label:has(+ select[data-validators~="required"]) {
  &:after {
    display: inline-block;
    padding-left: .2rem;
    content: " *";
    color: red;
  }
}
