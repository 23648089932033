@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import 'variables';
a sup {
    display: inline-block;
    text-decoration: none;
}
.shadow-top {
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        height: 10px;
        box-shadow: 0 1px 20px rgba(0,0,0,.2);
    }
}
.shadow-bottom {
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        height: 10px;
        box-shadow: 0 -1px 20px rgba(0,0,0,.2);
    }
}
.btn {
    border-radius: 0;
    color: $main-link-color;
    font-family: 'BentonSans-Medium';
    padding: .65rem 1.35rem .5rem;
    font-size: 1rem;
    &:focus {
        box-shadow: none;
    }
}
.btn-red {
    background-color: $main-second-text-color;
    color: white;
    border: 2px solid transparent;

    &:hover {
        color: $main-link-color;
        background-color: white;
        border: 2px solid $main-second-text-color;
    }
}
.text-red {
    color: $main-second-text-color;
}
.text-gray {
    color: $main-text-color !important;
}
.bg-blue-gradient {
    background-image: linear-gradient(to left, #fefeff, #eaeef2);
}
.mw-25 {
    max-width: 25% !important;
}
.mw-50 {
    max-width: 50% !important;
}
.mw-75 {
    max-width: 75% !important;
}
.text-break {
    word-break: break-all !important;
    overflow-wrap: break-word !important;
}
.br-desktop {
    display: inline;
    &:before {
        content: ' ';
    }
}
.video-border {
    border: 1px solid $main-text-color;
}
@include media-breakpoint-up(sm) {
    .mw-sm-20 {
        max-width: 20% !important;
    }
    .mw-sm-25 {
        max-width: 25% !important;
    }
    .mw-sm-50 {
        max-width: 50% !important;
    }
    .mw-sm-75 {
        max-width: 75% !important;
    }
    .mw-sm-100 {
        max-width: 100% !important;
    }
}
@include media-breakpoint-up(md) {
    .mw-md-20 {
        max-width: 20% !important;
    }
    .mw-md-25 {
        max-width: 25% !important;
    }
    .mw-md-50 {
        max-width: 50% !important;
    }
    .mw-md-75 {
        max-width: 75% !important;
    }
    .mw-md-90 {
        max-width: 90% !important;
    }
    .mw-md-100 {
        max-width: 100% !important;
    }
}
@include media-breakpoint-up(lg) {
    .mw-lg-20 {
        max-width: 20% !important;
    }
    .mw-lg-25 {
        max-width: 25% !important;
    }
    .mw-lg-50 {
        max-width: 50% !important;
    }
    .mw-lg-75 {
        max-width: 75% !important;
    }
    .mw-lg-100 {
        max-width: 100% !important;
    }
    .br-desktop {
        display: block;
        height: 0;
        overflow: hidden;
        &:before {
            content: none;
        }
    }
}
@include media-breakpoint-up(xl) {
    .mw-xl-20 {
        max-width: 20% !important;
    }
    .mw-xl-25 {
        max-width: 25% !important;
    }
    .mw-xl-50 {
        max-width: 50% !important;
    }
    .mw-xl-75 {
        max-width: 75% !important;
    }
    .mw-xl-90 {
        max-width: 90% !important;
    }
    .mw-xl-100 {
        max-width: 100% !important;
    }
}
