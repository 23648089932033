@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-accordion {
    border-bottom: 2px solid $main-second-text-color;
    .card {
        border-radius: 0;
        border-width: 0;
        background-color: transparent;
        .card-header {
            border-bottom: 0;
            border-top: 2px solid $main-second-text-color;
            padding: 1rem .75rem;
            border-radius: 0;
            background-color: transparent;
            h2 {
                margin-bottom: 0;
                button {
                    padding: 0;
                    font-family: 'CeraPro-Medium';
                    color: $main-second-text-color;
                    font-size: 1.15rem;
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    &[aria-expanded=false] {
                        .collapse-icon {
                            display: none;
                        }
                    }
                    &[aria-expanded=true] {
                        .expand-icon {
                            display: none;
                        }
                    }
                    span {
                        text-align: left;
                    }
                    img {
                        margin-left: 1em;
                    }
                }
            }
        }
        .card-body {
            padding: .75rem .75rem 2rem;
        }
    }
}
