@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-table-1 {
    color: $component-emphasized-text-color;
    font-size: 1rem;
    .row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 1.75rem auto;
        .col {
            &:first-child {
                display: inline-block;
                flex: 1 0 100%;
                padding: 0.75rem 0 0.5rem 0;
                margin-bottom: 0.75rem;
                border-bottom: 5px solid $main-second-text-color;
                font-family: 'CeraPro-Medium';
            }
            span {
                display: inline-block;
                padding: 0.25rem 0;
            }
            flex: 1 0 33%;
            font-family: 'CeraPro-Light';
            text-align: center;
        }
    }
}
@media (min-width: 414px) {
    .component-table-1 {
        font-size: 1.125rem;
    }
}
@include media-breakpoint-up(lg) {
    .component-table-1 {
        .row {
            flex-wrap: nowrap;
            align-items: center;
            padding: 0 0 1.5rem 0;
            border-bottom: 1px solid $navigation-color;
            .col {
                &:first-child {
                    display: inline-block;
                    flex: 1 0 25%;
                    padding-right: 1rem;
                    text-align: right;
                    border-right: 5px solid $main-second-text-color;
                    border-bottom: 0;
                    line-height: 1.25em;
                }
                flex: 1 0 12.5%;
            }
            &:last-child {
                margin-bottom: 0;
                border-bottom: 0;
            }
        }
    }
}
