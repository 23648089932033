@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-contact {
    background-image: url(/images/bg-contact.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 220%;
    text-align: center;
    position: relative;
    overflow: hidden;
    .container {
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: 25px;
        padding-right: 25px;
        position: relative;
        z-index: 1;
        h2, p {
            line-height: 1.5em;
            color: #ffffff;
        }
        h2 {
            font-family: 'CeraPro-Light';
            letter-spacing: -.01em;
            line-height: 1.11em;
            b {
                color: $main-third-text-color;
            }
        }
        p {
            font-family: 'CeraPro-Regular';
            margin-bottom: 2rem;
            a {
                color: #ffffff;
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .btn {
            background-color: #ffffff;
            border-radius: 0;
            font-family: 'CeraPro-Bold';
            color: $main-second-text-color;
            padding: 10px 30px;
            opacity: 1;
            transition: opacity .3s ease-in;
            text-decoration: none;
            &:hover {
                opacity: .9;
            }
        }
    }
    .parallax-background {
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        background-image: url(/images/bg-contact.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 220%;
    }
}
@include media-breakpoint-up(sm) {
    .component-contact {
        background-size: 140%;
        .container {
            padding-left: 60px;
            padding-right: 60px;
        }
        .parallax-background {
            background-size: 140%;
        }
    }
}
@include media-breakpoint-up(md) {
    .component-contact {
        background-size: 130%;
        .container {
            padding-left: 150px;
            padding-right: 150px;
        }
        .parallax-background {
            background-size: 130%;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-contact {
        background-size: 120%;
        .container {
            padding-top: 90px;
            padding-bottom: 90px;
            padding-left: 185px;
            padding-right: 185px;
            h2 {
                line-height: 1.2em;
            }
            p {
                margin-bottom: 2.75rem;
            }
        }
        .parallax-background {
            background-size: 120%;
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-contact {
        .container {
            padding-left: 210px;
            padding-right: 210px;
        }
    }
}
