@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-study-highlight {
    padding: 1rem 0;
    .highlight {
        color: $main-second-text-color;
        font-size: 5em;
        font-family: 'CeraPro-Light';
        line-height: 1em;
    }
    .description {
        padding-bottom: 0.5rem;
        color: $component-emphasized-text-color;
        font-size: 1rem;
    }
    .footnotes {
        font-family: 'CeraPro-Light';
        font-size: 80%;
    }
}
@media (min-width: 414px) {
    .component-study-highlight {
        .description {
            font-size: 1.125rem;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-study-highlight {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 1.5rem 0;
        .highlight {
            flex: 0 0 auto;
        }
        .description {
            flex: 1 0 auto;
            padding-left: 1rem;
            line-height: 1.25em;
        }
        .footnotes {
            flex: 1 0 100%;
        }
    }
}
