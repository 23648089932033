$topper-height: 32px;

$navigation-height-mobile: 100px;
$navigation-height-mobile-small: 80px;
$navigation-height-general-mobile: 100px;
$navigation-height-general-mobile-small: 80px;
$navigation-height-desktop: 195px;
$navigation-height-desktop-small: 80px;
$navigation-height-products-desktop: 55px;
$navigation-height-products-desktop-small: 0px;
$navigation-height-general-desktop: 140px;
$navigation-height-general-desktop-small: 80px;

$navigation-color: #64666b;

$products-nav-main-color: #ffffff;
$products-nav-main-text-color: #44474b;
$products-nav-second-text-color: #64666b;
$products-nav-borders-color: #d7d7d7;

$general-nav-main-color: #ee2938;
$general-nav-main-transparent-color: rgba(238,41,56,.93);
$general-nav-main-text-color-desktop: #64666b;
$general-nav-main-text-color-mobile: #ffffff;
$general-nav-second-text-color: #ffffff;

$main-text-color: #64676c;
$main-second-text-color: #ee2938;
$main-link-color: #ee2938;
$main-third-text-color: #99ca3c;

$template-home-text-color: #454649;
$component-alt-bg-color: #f4f7f9;
$component-emphasized-text-color: #444649;

$content-padding-top-desktop: 100px;
$content-padding-bottom-desktop: 100px;
$content-padding-top-mobile: 80px;
$content-padding-bottom-mobile: 70px;

$select-variants-height: 200px;

$footer-bg-deep-blue: #041c2c;
$footer-bg-dark-blue: #002855;
$footer-color: #fff;