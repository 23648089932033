@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-tabs {
    .nav {
        .nav-item {
            padding-left: 10px;
            padding-right: 10px;
            .nav-link {
                font-family: 'CeraPro-Medium';
                font-size: 1.25rem;
                line-height: 1.75em;
                color: $template-home-text-color;
                border-bottom: 5px solid $template-home-text-color;
                border-radius: 0;
                opacity: .7;
                background-color: transparent;
                padding: 0;
                &.active {
                    font-family: 'CeraPro-Bold';
                    color: $main-second-text-color;
                    border-bottom: 5px solid $main-second-text-color;
                    opacity: 1;
                    background-color: transparent;
                }
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
    .tab-content {
        padding-top: 40px;
    }
}
@include media-breakpoint-up(lg) {
    .component-tabs {
    }
}
