@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';
.jumbotron {
    padding: 0;
    background-color: #eef2f5;
    overflow: hidden;
    .container {
        padding-left: 15px;
        padding-right: 15px;
        h1 {
            font-family: 'CeraPro-Light';
            color: #444649;
            font-size: 1.625rem;
            line-height: 1.11em;
            margin-bottom: 0;
            b {
                font-family: 'CeraPro-Medium';
                color: $main-third-text-color;
            }
        }
    }
    &.branch, &.leaf, &.blood {
        position: relative;
        max-height: 391px;
        height: 70vw;
        .container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            height: 100%;
            h1 {
                position: relative;
                text-align: left;
                position: relative;
                padding-top: 2rem;
                font-size: 7.5vw;
                z-index: 1;
                width: 60%;
            }
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            background-repeat: no-repeat;
            background-position: center left;
            background-size: contain;
            z-index: 0;
            height: 100%;
            width: 100%;
        }
    }
    &.branch {
        &:before {
            background-image: url(/images/bg-header-branch-mobile.webp);
        }
    }
    &.leaf {
        &:before {
            background-image: url(/images/bg-header-leaf-mobile.webp);
        }
    }
    &.blood {
        .container {
            h1 {
                width: 65%;
                padding-top: 0;
            }
        }
        &:before {
            background-image: url(/images/bg-header-blood-mobile.webp);
            background-position: bottom left;
        }
    }
    &.no-image {
        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 200px;
            h1 {
                text-align: center;
            }
        }
    }
    &.video {
        margin-bottom: 0;
        position: relative;
        background-image: linear-gradient(to left, #fcfdfd, #eef2f5);
        video {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 56.25vw;
            width: 100vw;
            z-index: 0;
            display: none;
        }
        .container {
            background-image: url(/images/bg-header-home.webp);
            background-size: auto 90%;
            background-position: center left -40%;
            background-repeat: no-repeat;
            height: 80vw;
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .jumbotron-content {
                width: 50%;
                h1 {
                    font-size: 6vw;
                    margin-bottom: .85em;
                }
                .read-more {
                    font-size: 4.667vw;
                }
            }
            .video-control {
                background-color: rgba(0,0,0,0.4);
                padding: 0 10px;
                position: absolute;
                height: 48px;
                bottom: 5%;
                right: 0;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                display: none;
                .btn {
                    border: 0;
                    border-radius: 0;
                    flex-grow: 0;
                    flex-shrink: 0;
                    color: #fff;
                    padding: 0;
                    font-size: 28px;
                    height: 30px;
                    background-color: transparent;
                    background-image: none;
                    &.btn-skip,
                    &.btn-replay {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        i {
                            display: block;
                            width: 30px;
                            height: 30px;
                        }
                        span {
                            display: block;
                            margin-top: .1em;
                            margin-right: .5rem;
                            font-size: 1rem;
                        }
                    }
                    &.btn-skip {
                        i {
                            font-size: 30px;
                        }
                    }
                    &.btn-play {
                        width: 45px;
                    }
                    &.btn-pause {
                        width: 45px;
                    }
                    &.btn-volume-on {
                        width: 65px;
                    }
                    &.btn-volume-off {
                        width: 65px;
                    }
                }
                input[type=range] {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 100%;
                    margin-left: 5px;
                    padding: 0;
                    border-radius: 2px;
                    background: transparent;
                    &.disabled {
                        opacity: .5;
                    }
                }
                input[type=range]:focus {
                    outline: none;
                }
                input[type=range]::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 4px;
                    cursor: pointer;
                    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
                    background-color: #e0dfdf;
                    border-radius: 2px;
                }
                input[type=range]::-webkit-slider-thumb {
                    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
                    height: 14px;
                    width: 14px;
                    border-radius: 50%;
                    background: #ffffff;
                    border: 1px solid #ffffff;
                    cursor: pointer;
                    -webkit-appearance: none;
                    margin-top: -5px;
                }
                input[type=range]:focus::-webkit-slider-runnable-track {
                    background: #e0dfdf;
                }
                input[type=range]::-moz-range-track {
                    width: 100%;
                    height: 4px;
                    cursor: pointer;
                    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
                    background: #e0dfdf;
                    border-radius: 2px;
                }
                input[type=range]::-moz-range-thumb {
                    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
                    height: 14px;
                    width: 14px;
                    border-radius: 50%;
                    background: #ffffff;
                    border: 1px solid #ffffff;
                    cursor: pointer;
                }
                input[type=range]::-ms-track {
                    width: 100%;
                    height: 4px;
                    cursor: pointer;
                    background: transparent;
                    border-color: transparent;
                    border-width: 15px 0;
                    color: transparent;
                }
                input[type=range]::-ms-fill-lower {
                    background: #e0dfdf;
                    border-radius: 2px;
                    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
                }
                input[type=range]::-ms-fill-upper {
                    background: #e0dfdf;
                    border-radius: 2px;
                    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
                }
                input[type=range]::-ms-thumb {
                    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
                    height: 14px;
                    width: 14px;
                    border-radius: 50%;
                    background: #ffffff;
                    border: 1px solid #ffffff;
                    cursor: pointer;
                    margin: 0;
                }
                input[type=range]:focus::-ms-fill-lower {
                    background: #e0dfdf;
                }
                input[type=range]:focus::-ms-fill-upper {
                    background: #e0dfdf;
                }
            }
        }
    }
}
@media (min-width: 414px) {
    .jumbotron {
        .container {
            h1 {
                font-size: 2.375rem;
            }
        }
        &.branch, &.leaf, &.blood {
            .container {
                h1 {
                    font-size: 2.375rem;
                }
            }
        }
        &.video {
            .container {
                .jumbotron-content {
                    h1 {
                        font-size: 2.25rem;
                    }
                    .read-more {
                        font-size: 1.125rem;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .jumbotron {
        &.branch, &.leaf, &.blood {
            height: 180px;
            .container {
                padding-left: 0;
                padding-right: 0;
                h1 {
                    padding-top: 0;
                }
            }
            &:before {
                left: 0;
                background-position: bottom left;
                background-size: contain;
            }
        }
        &.branch {
            .container {
                h1 {
                    width: 48%;
                }
            }
            &:before {
                background-image: url(/images/bg-header-branch-desktop.webp);
                background-size: cover;
            }
        }
        &.leaf {
            .container {
                h1 {
                    width: 50%;
                }
            }
            &:before {
                background-image: url(/images/bg-header-leaf-desktop.webp);
            }
        }
        &.blood {
            .container {
                h1 {
                    width: 50%;
                }
            }
            &:before {
                background-image: url(/images/bg-header-blood-desktop.webp);
            }
        }
        &.no-image {
            .container {
                height: 150px;
            }
        }
        &.video {
            .container {
                height: 270px;
                background-position: center left;
                .jumbotron-content {
                    h1 {
                        font-size: 3rem;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .jumbotron {
        &.branch, &.leaf, &.blood {
            height: 240px;
            &:before {
                left: 0;
                right: auto;
                background-position: bottom left;
                background-size: contain;
            }
        }
        &.no-image {
            .container {
                padding-left: 70px;
                padding-right: 70px;
            }
        }
        &.video {
            .container {
                height: 360px;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .jumbotron {
        .container {
            h1 {
                line-height: 1.21em;
            }
        }
        &.branch, &.leaf, &.blood {
            height: 320px;
        }
        &.no-image {
            .container {
                height: 200px;
                padding-left: 90px;
                padding-right: 90px;
            }
        }
        &.video {
            video {
                display: block;
            }
            .container {
                height: 56.25vw;
                background-image: none;
                .jumbotron-content {
                    h1,
                    .read-more {
                        opacity: 0;
                    }
                }
                &.content-animated {
                    .jumbotron-content {
                        h1 {
                            transition: opacity 1s ease-in;
                        }
                        .read-more {
                            transition: opacity .5s ease-in 3s;
                        }
                    }
                }
                &.content-shown {
                    .jumbotron-content {
                        h1,
                        .read-more {
                            opacity: 1;
                        }
                    }
                }
                .video-control {
                    display: flex;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .jumbotron {
        &.branch, &.leaf, &.blood {
            height: 380px;
        }
        &.no-image {
            .container {
                height: 240px;
            }
        }
        &.video {
            .container {
                height: 43vw;
            }
        }
    }
}
