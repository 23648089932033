@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.breadcrumb {
    margin-bottom: 0;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    .breadcrumb-item {
        font-family: 'BentonSans-Medium';
        font-size: .625rem;
        a {
            color: $main-second-text-color;
        }
        & + .breadcrumb-item {
            &:before {
                content: '\003E';
            }
        }
    }
}
