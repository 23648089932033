@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-intro {
    &.home-intro {
        padding-top: 100px;
        padding-bottom: 25px;
        .holder {
            padding: 16px 25px;
            border-left: 2px solid $main-third-text-color;
            opacity: 0;
            transform: translateY(10px);
            transition: opacity .3s ease-in, transform .3s ease-in;
            p {
                font-family: 'CeraPro-Light';
                font-size: 1.375rem;
                line-height: 1.35em;
                font-weight: normal;
                color: $main-text-color;
                margin-bottom: 0;
            }
            &.visible {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
@media (min-width: 414px) {
    .component-intro {
        &.home-intro {
            p {
                font-size: 1.375rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-intro {
        &.home-intro {
            p {
                width: 85%;
                line-height: 1.35em;
                letter-spacing: .01em;
            }
        }
    }
}
