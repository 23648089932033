@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-table-2 {
    font-size: 0.8em;
    h3 {
        margin-bottom: 0;
        padding: 0.5rem 1rem;
        font-family: 'CeraPro-Medium';
        text-align: center;
        background-color: $main-second-text-color;
        color: white;
    }
    p {
        display: flex;
        flex: 1 0 25%;
        margin: 1rem 0;
        font-size: 4vw;
        b {
            flex: 1 0 33%;
            padding-right: 3rem;
            color: $main-second-text-color;
            font-family: 'CeraPro-Medium';
            text-align: right;
        }
        span {
            flex: 1 0 33%;
            font-family: 'CeraPro-Regular';
        }
    }
    .footnotes {
        padding: 0.5rem;
        border-top: 1px solid $main-second-text-color;
        text-align: center;
        font-family: 'CeraPro-Light';
    }
}
@media (min-width: 414px) {
    .component-table-2 {
        p {
            font-size: 1.125rem;
        }
    }
}
@include media-breakpoint-up(md) {
    .component-table-2 {
        p {
            display: block;
            b, span {
                display: block;
                margin: 0.5rem 0;
                padding: 0;
                text-align: left;
            }
        }
    }
}
