@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';
@keyframes shape-1 {
    0%, 100% {
        transform: translate3d(0, 0, 0);
    }
    20% {
        transform: translate3d(0, -2%, 0) rotate(-5deg);
    }
    40% {
        transform: translate3d(2%, 0, 0);
    }
    60% {
        transform: translate3d(0, 2%, 0) rotate(5deg);
    }
    80% {
        transform: translate3d(-2%, 0, 0);
    }
}
@keyframes shape-2 {
    0%, 100% {
        transform: translate3d(0, 0, 0);
    }
    20% {
        transform: translate3d(0, -5%, 0) rotate(-5deg);
    }
    40% {
        transform: translate3d(-5%, 0, 0);
    }
    60% {
        transform: translate3d(0, 5%, 0) rotate(5deg);
    }
    80% {
        transform: translate3d(5%, 0, 0);
    }
}
@keyframes shape-3 {
    0%, 100% {
        transform: translate3d(0, 0, 0);
    }
    25% {
        transform: translate3d(5%, 0, 0);
    }
    50% {
        transform: translate3d(0, 5%, 0) rotate(5deg);
    }
    45% {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes shape-4 {
    0%, 100% {
        transform: translate3d(0, 0, 0);
    }
    25% {
        transform: translate3d(-5%, 0, 0);
    }
    50% {
        transform: translate3d(0, -5%, 0) rotate(-5deg);
    }
    45% {
        transform: translate3d(0, 0, 0);
    }
}

.component-cells {
    background-image: linear-gradient(to bottom, #ffffff, #e9eef2);
    height: 110vw;
    min-height: 540px;
    position: relative;
    overflow: hidden;
    .container {
        padding-top: $content-padding-top-mobile;
        height: 100%;
        position: relative;
        .cells-content {
            position: relative;
            z-index: 1;
            width: 100%;
        }
        .cells {
            background-position: bottom right;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(/images/cells.webp);
            position: absolute;
            z-index: 0;
            right: 0;
            bottom: 5%;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity .5s ease-in;
        }
        .cells-layer {
            position: absolute;
            z-index: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity .5s ease-in;
        }
        .cell {
            z-index: 0;
            position: absolute;
            overflow: visible;
            &:after {
                content: '';
                display: block;
                width: 100%;
                padding-bottom: 100%;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                transform-origin: center;
            }
            &.cell-1 {
                min-width: 100px;
                max-width: 180px;
                width: 45%;
                bottom: -7%;
                left: 30%;
                &:after {
                    background-image: url(/images/cell-1.webp);
                }
            }
            &.cell-2 {
                min-width: 35px;
                max-width: 60px;
                width: 50%;
                bottom: 18%;
                left: 20%;
                &:after {
                    background-image: url(/images/cell-2.webp);
                }
            }
            &.cell-3 {
                min-width: 35px;
                max-width: 60px;
                width: 50%;
                bottom: 5%;
                left: 80%;
                &:after {
                    background-image: url(/images/cell-2.webp);
                }
            }
            &.cell-4 {
                min-width: 20px;
                max-width: 30px;
                width: 50%;
                bottom: 3%;
                left: 10%;
                &:after {
                    background-image: url(/images/cell-4.webp);
                }
            }
            &.cell-5 {
                min-width: 20px;
                max-width: 30px;
                width: 50%;
                bottom: 15%;
                left: 75%;
                &:after {
                    background-image: url(/images/cell-4.webp);
                }
            }
            &.cell-6 {
                min-width: 20px;
                max-width: 30px;
                width: 50%;
                bottom: 40%;
                left: 85%;
                &:after {
                    background-image: url(/images/cell-5.webp);
                }
            }
        }
    }
    &.visible {
        .container {
            .cells {
                opacity: 1;
            }
            .cells-layer {
                opacity: 1;
            }
            .cell {
                &:after {
                    animation-iteration-count: infinite;
                    animation-delay: inherit;
                    animation-duration: inherit;
                }
                &.cell-1 {
                    &:after {
                        animation-name: shape-1;
                    }
                }
                &.cell-2 {
                    &:after {
                        animation-name: shape-2;
                    }
                }
                &.cell-3 {
                    &:after {
                        animation-name: shape-3;
                    }
                }
                &.cell-4 {
                    &:after {
                        animation-name: shape-4;
                    }
                }
                &.cell-5 {
                    &:after {
                        animation-name: shape-2;
                    }
                }
                &.cell-6 {
                    &:after {
                        animation-name: shape-1;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-cells {
        height: 540px;
    }
}
@include media-breakpoint-up(lg) {
    .component-cells {
        height: 720px;
        .container {
            padding-top: $content-padding-top-desktop;
            .cells-content {
                width: 80%;
            }
            .cells {
                background-position: bottom right;
                background-size: 80%;
            }
            .cell {
                &.cell-1 {
                    min-width: 200px;
                    max-width: 300px;
                    bottom: -20%;
                }
                &.cell-2 {
                    min-width: 60px;
                    max-width: 100px;
                }
                &.cell-3 {
                    min-width: 60px;
                    max-width: 100px;
                }
                &.cell-4 {
                    min-width: 30px;
                    max-width: 50px;
                }
                &.cell-5 {
                    min-width: 30px;
                    max-width: 50px;
                }
                &.cell-6 {
                    min-width: 30px;
                    max-width: 50px;
                }
            }
        }
    }
}
