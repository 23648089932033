@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-sign-up {
    background-color: $main-second-text-color;
    .container {
        padding-top: 70px;
        padding-bottom: 70px;
        text-align: center;
        h3 {
            color: #ffffff;
            font-family: 'CeraPro-Light';
            font-weight: normal;
            line-height: 1.0625em;
            letter-spacing: .02em;
            text-align: inherit;
            margin-bottom: 1em;
        }
        .btn {
            background-color: #ffffff;
            font-family: 'CeraPro-Medium';
            line-height: 1.15em;
            letter-spacing: .005em;
            padding: 12px 40px;
            border-radius: 0;
            color: $main-second-text-color;
        }
    }
}
@include media-breakpoint-up(md) {
    .component-sign-up {
        .container {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-sign-up {
        .container {
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            h3 {
                font-family: 'CeraPro-Regular';
                letter-spacing: .04em;
                margin-bottom: 0;
            }
            .btn {
                font-family: 'CeraPro-Bold';
                margin-left: 60px;
            }
        }
    }
}
