.component-form-email-signup {
    .close {
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
    form {
        p {
            font-size: 1rem;
        }
    }
    .message-success {
        display: none;
    }
    &.success {
        .message-success {
            display: block;
        }
        .container-form {
            display: none;
        }
    }
}