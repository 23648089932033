@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-references {
    padding-top: 0;
    padding-bottom: 40px;
    .container {
        h3 {
            display: inline;
            padding-right: 0.5rem;
            font-family: 'CeraPro-Bold';
            font-size: .9rem;
            color: $main-text-color;
        }
        ol {
            display: inline;
            padding: 0;
            margin: 0;
            counter-reset: item;
            line-height: 0;
            li {
                display: inline;
                list-style-type: none;
                counter-increment: item;
                font-family: 'CeraPro-Regular';
                font-size: .9rem;
                line-height: 1em;
                white-space: nowrap;
                &::before {
                    font-family: 'CeraPro-Bold';
                    padding-right: 0.2em;
                    text-align: left;
                    content: counter(item) ".";
                }
                .text-break {
                    word-break: break-all !important;
                    overflow-wrap: break-word !important;
                }
                & > span {
                    white-space: normal;
                    margin-right: 0.25rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .component-references {
        padding-top: 0;
        padding-bottom: 100px;
    }
}
