@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../css/variables';

.template-home {
    color: $template-home-text-color;
    h2 {
        font-family: 'CeraPro-Light';
        line-height: 1.2em;
        color: $template-home-text-color;
        b {
            color: $main-third-text-color;
        }
    }
    .component-circle-of-care,
    .component-references {
        color: $main-text-color;
    }
    .component-tabs {
        display: none;
        text-align: center;
        h3 {
            font-family: 'CeraPro-Regular';
            font-size: 1.5rem;
            line-height: .9em;
            margin-bottom: .5em;
            color: $template-home-text-color;
        }
    }
    .component-accordion {
        display: block;
        text-align: left;
        .card-body {
            h3 {
                font-family: 'CeraPro-Regular';
                line-height: 1.1em;
                margin-bottom: .5em;
                color: $template-home-text-color;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .template-home {
        h2 {
            margin-bottom: 2rem;
        }
        .component-tabs {
            display: block;
        }
        .component-accordion {
            display: none;
        }
    }
}
