@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-callout {
    display: flex;
    align-items: center;
    color: $component-emphasized-text-color;
    .col {
        flex: 1 1 67%;
        margin-left: 0;
        padding-left: 0;
        font-family: 'CeraPro-Light';
        font-size: 1rem;
        line-height: 1.25em;
        &:first-child {
            flex: 0 0 25%;
            padding-left: 15px;
            font-family: 'CeraPro-Thin';
            font-size: 3em;
            line-height: 1em;
            border-left: 1px solid $main-third-text-color;
        }
    }
}
@media (min-width: 414px) {
    .component-callout {
        .col {
            font-size: 1.125rem;
        }
    }
}
@include media-breakpoint-up(sm) {
    .component-callout {
        width: 60%;
        margin: 0 auto;
        .col {
            flex: 1 0 auto;
            padding-left: 15px;
            &:first-child {
                margin-left: 0.5rem;
                font-size: 4em;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-callout {
        width: 40%;
    }
}
